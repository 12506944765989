import { Link, defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

import * as s from './CookieBanner.module.css';

const messages = defineMessages({
  message: {
    id: 'cookieBanner.message',
    defaultMessage:
      'This website uses cookies. Read our <url>privacy policy</url>',
  },
  acceptCookiesButton: {
    id: 'cookieBanner.acceptCookiesButton',
    defaultMessage: 'Ok, I got it!',
  },
  acceptCookiesShortButton: {
    id: 'cookieBanner.acceptCookiesShortButton',
    defaultMessage: 'Ok',
  },
});

const COOKIE_NAME = 'cookies-accepted';

const CookieBanner = () => {
  const { formatMessage } = useIntl();
  const [cookies, setCookie] = useCookies([COOKIE_NAME]);
  const [isVisible, setVisibility] = useState(!cookies[COOKIE_NAME]);

  const hideBanner = () => setVisibility(false);

  const onAccept = () => {
    setCookie(COOKIE_NAME, true, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365, // 365 days
    });
    hideBanner();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div id="cookieBanner" className={s.root}>
      <div className={s.container}>
        <span>
          {formatMessage(messages.message, {
            url: (value) => <Link to="/privacy-policy/">{value}</Link>,
          })}
        </span>
        <button type="button" className={s.button} onClick={onAccept}>
          <span className={s.buttonMobileCopy}>
            {formatMessage(messages.acceptCookiesShortButton)}
          </span>
          <span className={s.buttonDesktopCopy}>
            {formatMessage(messages.acceptCookiesButton)}
          </span>
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
